<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Last Login User </h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
              <CCol md="2"> 
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>        
            <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
            </CRow>  
            <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                  </CCol> 
            </CRow> 


                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>



            <CRow> 
                <CCol md="4">                    
                        <CInput
                            label="From Date"
                            type="date"
                            vertical
                            v-model="fromDate" 
                            @change="startDateOnChange" 
                        />       
                </CCol>
                <CCol md="4">                    
                        <CInput
                            label="To Date"
                            type="date"
                            vertical
                            v-model="toDate"
                            @change="endDateOnChange"                       
                        />         
                </CCol>  
                 <CCol md="4">                    
                        <CInput
                            label="Total User"                            
                            vertical
                            :disabled="!isDisablttlusr" 
                            v-model="total"                                               
                        />         
                </CCol>  
            </CRow>

        <div class="table-responsive table">
        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" sort-by="lastLogin" sort-desc="true" :per-page="perPage" :filter="filter">

            <!-- <template v-slot:cell(userId)="{ item }"> 
                {{ item.userId | IdSubstr }}
            </template> -->

            <template v-slot:cell(lastLogin)="{ item }"> 
                {{ item.lastLogin }}
            </template>

            <template v-slot:cell(userStatus)="{ item }"> 
                {{ item.userStatus | upper }}
            </template>
      </b-table>
      </div>

      <div>
        <b-row>
          <b-col sm="3">
             <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
          </b-col>       
          <b-col sm="9">
             <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
             </b-form-select>
          </b-col>
        </b-row>      
      </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import moment from 'moment';

 export default {
    name: "userreport",
    data() { var date = new Date();
      return {
        items:[],             
        fields: [   
          {
            name : 'userId',
            key: 'userId',
            label : 'User ID',
            sortable: true
          },  
          {
            key: 'firstName',
            label : 'First Name',
            sortable: true
          },  
          {
            key: 'lastName',
            label : 'Last Name',
            sortable: true
          },             
          {
            key: 'mobileNumber',
            label : 'Phone No.',
            sortable: true
          },           
          {
            key: 'lastLogin',
            label : 'Last Login',
            sortable: true
          },          
          {
            key: 'userStatus',
            label : 'Status',
            sortable: true
          },  
          {
            key: 'lastLoginIp',
            label : 'IP Address',
            sortable: true
          }              
         
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59).toISOString().substr(0, 10), 
        data:[],
        columns:[], 
        formPasswd:false,
        filename:"last-login-user-report.xlsx",
        password:"",
        spinner:false,
        seen:false,
        msg :'',
        color:'',
        total:0,
        isDisablttlusr:false,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10) + '...'            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
    },

    created () { 
       this.spinner = true;
       this.retriveListLastLoginUsers();
       this.setDefaultPasswd(); this.setColumns();        
    },
    methods: {  

      showFormPasswd(){
          this.formPasswd = true;
      },

      actionCancel(){
            this.formPasswd = false;
      },
        
      setDefaultPasswd(){
          var today = moment().format('DDMMYYYYHHmmss');
          var user  = this.$store.getters.getUser; 
          this.password = user+today;
      },

      setColumns(){ 
        this.columns = [];
        var fields = this.fields; let row = {}; var cols = [];
        var except = ['index','details'];
        for (var key in fields) {  
           var vkey = fields[key].key;
           if(!except.includes(vkey)){         
             row['label'] = fields[key].label;
             row['field'] = fields[key].key;
             var temp = JSON.stringify(row); cols.push(temp);
           }
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      setDataRows(resp,keydate){

            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);            
            
            for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){ 
                      var strdate = resp2[key2]; 
                      //var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                      var ts = new Date(strdate).getTime();
                      var dt = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");                      
                      resp[key][keydate] = dt;                     
                    }                    
                 }
            }           

          this.data  = resp;               
      },
        
      generateWorkbook(){

          this.formPasswd = false; this.spinner=true;
          var cols  = this.columns; var rows  = this.data;        
          var heads = []; var temp =[]; var data=[];
          let params = {};   var colkey =[];      

            for (var key in cols) {
              var col = cols[key]; heads.push(col.label);
              colkey.push(col.field);
            } 
            data.push(heads);  var jum = heads.length; 

            for (var key2 in rows) { var row = rows[key2]; 
                var init = Array(jum).fill(null);
                for(var key4 in colkey){
                  for (var key3 in row) { 
                    var val  = row[key3]; 
                    // if(key3==="lastLogin"){
                    //   var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                    // }                
                    var ckey = colkey[key4]; 
                    if(key3===ckey){ 
                      init[key4] = val; 
                    }              
                  }
                }
                data.push(init); 
            } 

          params['password'] = this.password;
          params['data'] = data;   //console.log(params); 
          this.getReportExcel(params);
      },

      getReportExcel: function(params) {
          ReportService.getReportExcel(params).then(resp => {             
          try {  
              if(resp.status==200){
                var filename = this.filename;
                const link = document.createElement('a');    
                link.href = window.URL.createObjectURL(
                    new Blob([resp.data])
                );   
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click(); this.spinner = false; 
              } else {
                this.msg   = 'Sorry, Generated Excel Failed!'; 
                this.color = 'warning'; this.seen  = true;
                this.spinner = false; 
                setTimeout( () =>  this.seen=false , 5000);
              }
            }
          catch(err) {            
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
          } 

          }, error => { 
            this.spinner = false;            
          });
      }, 
        
      startDateOnChange(){
          this.retriveListLastLoginUsers();
      },

      endDateOnChange(){
          this.retriveListLastLoginUsers();
      },

      retriveListLastLoginUsers(){
        this.spinner = true;
        let params = {};       
        params['fromDate'] = this.fromDate.toString()+"T00:00:00";
        params['toDate']   = this.toDate.toString()+"T23:59:59";
        this.getListLastLoginUsers(params);    

      },

      getListLastLoginUsers: function(params) {
        AuthService.getListLastLoginUsers(params).then(resp => {  //console.log(resp);         
          let rows = [];
          for (var key in resp) { 
              var temp = resp[key];
                  let row = {
                      "userId"       : temp.userIdentify.userId, 
                      "firstName"    : temp.firstName,
                      "lastName"     : temp.lastName,
                      "mobileNumber" : temp.mobileNumber.number,
                      "lastLogin"    : temp.lastLogin,
                      "userStatus"   : temp.userStatus,
                      "lastLoginIp"  : temp.lastLoginIp,                      
                   }; 
                   rows.push(row); 
          }

          this.items = rows;  this.setDataRows(rows,"lastLogin"); 
          this.total = resp.length; 
          this.spinner = false;
        }, error => {
          this.spinner = false;
        });
      }
    },
    mounted() { 
       //console.log(process.env.VUE_APP_ROOT_API);
    },   
 }; 

</script>

<style>
    .table{ overflow-y:scroll; }
</style>